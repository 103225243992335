import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/usr/src/app/node_modules/next-themes/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/app/components/locale/locale.selector.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/components/theme/theme.switch.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/app/components/ui/badge/exchange.status.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/components/ui/icons/smart.logo.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["SocketProvider"] */ "/usr/src/app/src/provider/socket.provider.tsx");
