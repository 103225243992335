"use client";
import { ChevronDownIcon } from "@radix-ui/react-icons";
import Image from "next/image";
import { useLocale } from "next-intl";
import { supportedLocalesList } from "src/i18n/locales";
import { placeholderImage } from "@utils/constants";
import Link from "next/link";
import { usePathname } from "next/navigation";

export default function LocaleSelector() {
  const locale = useLocale();
  const pathname = usePathname();

  const generateLocalePath = (destinationLocale: string) => {
    if (locale === "en" && destinationLocale !== "en") {
      return `/${destinationLocale}${pathname}`;
    }
    return pathname.replace(`/${locale}`, `/${destinationLocale}`);
  };

  return (
    <div className="dropdown w-[70px]">
      <div
        tabIndex={0}
        role="button"
        className="flex items-center   gap-1 justify-center w-full  p-1  focus:outline-none"
      >
        <span className="flex items-center gap-1 text-sm font-medium ">
          <Image
            className="rounded-full mr-1"
            src={supportedLocalesList.find((l) => l.code === locale)?.icon || placeholderImage}
            alt={`${locale} flag`}
            width={16}
            height={16}
          />
          <span className="text-sm font-medium  w-5"> {locale.toUpperCase()}</span>
        </span>
        <ChevronDownIcon />
      </div>
      <ul
        tabIndex={0}
        className="dropdown-content menu bg-base-100 rounded-box z-[1] w-[90px] shadow"
      >
        {supportedLocalesList.map((language) => (
          <li key={language.code}>
            <Link
              href={generateLocalePath(language.code)}
              className="flex self-stretch items-center justify-between"
            >
              <Image
                className="rounded-full"
                src={language.icon}
                alt={`${language} flag`}
                width={16}
                height={16}
              />
              <span className="text-sm font-medium w-5"> {language.label}</span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
