"use client";
import ClockIcon from "@public/svg/icon/clock.icon.svg";
import { ExchangeDataType, MarketType, SocketBroadcastType } from "@type/types";
import { getMarketTimeDurationBasedOnLocalTime } from "@utils/date.transformer";
import { setImagePath, transformTimeZoneToName } from "@utils/transformer";
import Image from "next/image";
import { useState } from "react";

interface ExchangeStatusProps {
  exchange: ExchangeDataType;
}

export default function ExchangeStatus({ exchange }: ExchangeStatusProps) {
  const [date, setDate] = useState<Date>(new Date());
  const { isOpen, timeDurationString } = getMarketTimeDurationBasedOnLocalTime(exchange, date);

  if (!exchange) {
    return null;
  }
  return (
    <div className="flex flex-row items-center gap-1 min-w-[180px]">
      <Image
        src={setImagePath(exchange?.logo) || ""}
        alt={`${exchange.timezone} flag`}
        width={16}
        height={16}
      />
      <span className="text-xs mr-2">{transformTimeZoneToName(exchange?.timezone)}</span>
      <ClockIcon width={14} height={14} className={isOpen ? "fill-green-500" : "fill-red-500"} />
      <span className="text-xxs font-thin">{timeDurationString}</span>
    </div>
  );
}
