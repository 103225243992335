import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { ExchangeDataType } from "@type/types";

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

export type MarketTimeDuration = {
  isOpen: boolean;
  timeDurationString: string;
};

export const getMarketTimeDurationBasedOnLocalTime = (
  marketStats?: ExchangeDataType,
  localNowDate?: Date
): MarketTimeDuration => {
  if (!marketStats || !localNowDate) {
    return { isOpen: false, timeDurationString: "" };
  }
  const { openingHour, closingHour, timezone, holidays, closingAdditional, openingAdditional } =
    marketStats;
  const isTodayHolidayOnHolidayList = holidays
    ?.find((holiday) => holiday.year?.toString() === new Date().getFullYear().toString())
    ?.dates?.find((date) => dayjs(date).isSame(dayjs(), "day"));

  const dayOfWeek = dayjs().day();

  const isTodayIsWeeklyHoliday = dayOfWeek === 0 || dayOfWeek === 6;

  const isTodayHoliday = !!isTodayHolidayOnHolidayList || isTodayIsWeeklyHoliday;

  if (isTodayHoliday) {
    return { isOpen: false, timeDurationString: "" };
  }
  if (!openingHour || !closingHour || !timezone) {
    return { isOpen: false, timeDurationString: "" };
  }

  var openingLocalTime = new Date(getLocalTime(openingHour, timezone));
  var closingLocalTime = new Date(getLocalTime(closingHour, timezone));

  if (
    (localNowDate < openingLocalTime || localNowDate > closingLocalTime) &&
    openingAdditional &&
    closingAdditional
  ) {
    openingLocalTime = new Date(getLocalTime(openingAdditional, timezone));
    closingLocalTime = new Date(getLocalTime(closingAdditional, timezone));
  }

  const isOpen = localNowDate >= openingLocalTime && localNowDate <= closingLocalTime;

  //   console.log(timezone, isOpen);

  return {
    isOpen,
    timeDurationString: `${dayjs(openingLocalTime).format("hh:mm A")} to ${dayjs(
      closingLocalTime
    ).format("hh:mm A")}`,
  };
};

const getLocalTime = (timeString: string, timezone: string): Date => {
  const [time, period] = timeString.split(" ");
  const timeStringResult = `${time} ${period.split(".").join("").toUpperCase()}`;

  const parsedTime = dayjs.tz(`${timeStringResult}`, "hh:mm A", timezone);

  //   const parsedTime = dayjs(timeStringResult, "hh:mm A");

  return parsedTime.local().toDate();
};
