"use client";
import * as React from "react";
import { useTheme } from "next-themes";
import clsx from "clsx";
import Image from "next/image";
import { darkLogoPath, lightLogoPath } from "@config/constants";

export const SmartLogo = ({ size }: { size?: number }) => {
  const [mounted, setMounted] = React.useState(false);

  const { theme } = useTheme();

  React.useEffect(() => {
    let isMounted = true;

    setMounted(isMounted);
    return () => {
      isMounted = false;
    };
  }, []);

  if (!mounted) {
    return null;
  }
  if (!theme) {
    return null;
  }
  return (
    <Image
      src={theme === "dark" ? darkLogoPath : lightLogoPath}
      alt="Logo"
      width={size || 30}
      height={size || 30}
    />
  );
};
